<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      orderdetail: [],
      ismobile: false,
      orderinfo: [],
      activeapp: [],
      userinfo: [],
      ordersharingdata: [],
      loading: true,
      systemtype: 0,
      printer: "",
      ordererror: false,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.devices.orderinfo");
    this.items = [
      {
        text: this.$t("menuitems.devices.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.devices.orders"),
        href: "/device/orders",
      },
      {
        text: this.$t("menuitems.devices.orderinfo"),
        active: true,
      },
    ];

    this.userinfo = JSON.parse(localStorage.getItem("user"));
    this.getorderinfo();
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.getsysteminfo();
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtype = parseInt(response.data.data.model);
          that.printer = response.data.data.printer;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getorderinfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "orderinfo",
            id: that.$route.query.id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.orderdetail = response.data.data;
            that.orderinfo = response.data.infos;
          } else {
            that.ordererror = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getseparateinfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getsharingdata",
            id: that.$route.query.id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          if (response.data.status == 200) {
            that.ordersharingdata = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    print_ticket: function () {
      var that = this;
      that.$toast.loading({
        message: that.$t("global.loading.reading"),
        forbidClick: true,
      });
      that.$axios
        .post(
          that.apiuri,
          {
            action: "orderprint",
            id: that.$route.query.id,
            printer: that.printer,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
            that.getorderinfo();
          } else if ((response.data.status = 404)) {
            that.$message.error(that.$t("device.orders.info.noprint"));
          } else {
            that.$message.error(that.$t("global.failed"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  filters: {
    formatTimer: function (value) {
      value = value * 1000;
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading" v-if="!ordererror">
            <table class="table table-hover">
              <tbody>
                <tr>
                  <td>{{ $t("device.orders.info.sn") }}</td>
                  <td>{{ orderdetail.sn }}</td>
                </tr>
                <tr>
                  <td>{{ $t("device.orders.info.id") }}</td>
                  <td>{{ orderdetail.ED_id }}</td>
                </tr>
                <tr v-if="systemtype == 0">
                  <td>{{ $t("device.orders.info.goods") }}</td>
                  <td>{{ orderdetail.gid }}</td>
                </tr>
                <tr>
                  <td>{{ $t("device.orders.info.price") }}</td>
                  <td>
                    {{ orderdetail.total }}-{{ orderdetail.refun_fee
                    }}{{ $t("device.orders.info.yuan") }}({{
                      orderdetail.goodstotal
                    }}-{{
                      orderdetail.goodstotal *
                      (orderdetail.refun_fee / orderdetail.total)
                    }}<span v-if="orderdetail.unity == 0">{{
                      $t("device.orders.info.ml")
                    }}</span
                    ><span v-if="orderdetail.unity == 1">CC</span
                    ><span v-if="orderdetail.unity == 2"
                      >{{ $t("device.orders.info.ml") }}/{{
                        $t("device.orders.info.cup")
                      }}</span
                    ><span v-if="orderdetail.unity == 3"
                      >{{ $t("device.orders.info.ml") }}/{{
                        $t("device.orders.info.bottle")
                      }}</span
                    >)
                  </td>
                </tr>
                <tr v-if="orderdetail.surpgoods > 0">
                  <td>{{ $t("device.orders.info.baseinfo") }}</td>
                  <td>
                    {{ $t("device.orders.info.value") }}:{{
                      orderdetail.goodstotal
                    }}
                    {{ $t("device.orders.info.report") }}:{{
                      orderdetail.surpgoods
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("device.orders.info.creaet") }}</td>
                  <td>{{ orderdetail.addtime | formatTimer }}</td>
                </tr>
                <tr>
                  <td>{{ $t("device.orders.info.pay") }}</td>
                  <td>{{ orderdetail.paytime | formatTimer }}</td>
                </tr>

                <tr v-if="systemtype != 1">
                  <td>{{ $t("device.orders.info.type") }}</td>
                  <td>
                    <span v-if="orderdetail.payway == 0">{{
                      $t("device.orders.payment.cash")
                    }}</span>
                    <span v-if="orderdetail.payway == 1">{{
                      $t("device.orders.payment.wallet")
                    }}</span>
                    <span v-if="orderdetail.payway == 2"
                      ><i class="fab fa-alipay" style="color: #4dabf7"></i
                      >{{ $t("device.orders.payment.alipay") }}</span
                    >
                    <span v-if="orderdetail.payway == 3"
                      ><i class="fab fa-weixin" style="color: #3cb034"></i
                      >{{ $t("device.orders.payment.wxpay") }}</span
                    >
                    <span v-if="orderdetail.payway == 4"
                      ><i class="fab fa-apple-pay"></i
                      >{{ $t("device.orders.payment.applepay") }}</span
                    >
                    <span v-if="orderdetail.payway == 5"
                      ><i class="fab fa-google-wallet"></i
                      >{{ $t("device.orders.payment.googlewallet") }}</span
                    >
                    <span v-if="orderdetail.payway == 6"
                      ><i class="fab fa-amazon-pay"></i
                      >{{ $t("device.orders.payment.amazonpay") }}</span
                    >
                    <span v-if="orderdetail.payway == 7"
                      ><i class="fab fa-qq"></i
                      >{{ $t("device.orders.payment.qpay") }}</span
                    >
                    <span v-if="orderdetail.payway == 40"
                      ><i class="fab fa-bestpay"></i
                      >{{ $t("device.orders.payment.bestpay") }}</span
                    >
                  </td>
                </tr>
                <tr v-if="systemtype != 1">
                  <td>{{ $t("device.orders.info.status") }}</td>
                  <td>
                    <label
                      class="badge badge-secondary"
                      v-if="
                        orderdetail.paytime > 0 && orderdetail.refun_fee == 0
                      "
                      >{{ $t("device.orders.status.orderpay") }}</label
                    >
                    <label
                      class="badge badge-danger"
                      v-if="orderdetail.paytime == 0"
                      >{{ $t("device.orders.status.nopay") }}</label
                    >
                    <label
                      class="badge badge-success"
                      v-if="
                        orderdetail.feedback == 1 &&
                        orderdetail.paytime > 0 &&
                        orderdetail.refun_fee == 0
                      "
                      >{{ $t("device.orders.status.pushdone") }}</label
                    >
                    <label
                      class="badge badge-warning"
                      v-if="
                        orderdetail.refun_fee > 0 && orderdetail.paytime > 0
                      "
                      >{{ $t("device.orders.status.refund") }}</label
                    >
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("device.orders.info.ticket") }}</td>
                  <td>
                    <a href="javascript:;">
                      <el-tag
                        type="danger"
                        v-if="orderdetail.ticket == 0"
                        @click="print_ticket"
                        >{{ $t("device.orders.info.noprintticket") }}</el-tag
                      >
                      <el-tag type="success" v-else @click="print_ticket">{{
                        $t("device.orders.info.printticket", {
                          pice: orderdetail.ticket,
                        })
                      }}</el-tag>
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("device.orders.info.mark") }}</td>
                  <td>
                    <el-tag
                        type="danger"
                        v-if="orderdetail.mark"
                        >{{ orderdetail.mark }}</el-tag
                      >
                      <span v-else></span>
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table table-hover">
              <thead>
                <tr>
                  <th>{{ $t("device.orders.info.listno") }}</th>
                  <th>{{ $t("device.hd.no") }}</th>
                  <th>{{ $t("device.orders.info.goods") }}</th>
                  <th v-if="systemtype == 2">
                    {{ $t("device.orders.info.user") }}
                  </th>
                  <th>{{ $t("device.orders.orderprice") }}</th>
                  <th>{{ $t("device.orders.salsevalues") }}</th>
                  <th>{{ $t("device.orders.dissipate") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, idx) in orderinfo" :key="idx">
                  <td>{{ idx + 1 }}</td>
                  <td>
                    <span v-if="!val.edid">{{ orderdetail.ED_id }}</span>
                    <span v-else>{{ val.edid }}</span>
                    #{{ val.hd_no }}
                  </td>
                  <td v-if="val.title">{{ val.title }}</td>
                  <td v-else>{{ $t("device.orders.nogoodsname") }}</td>
                  <td v-if="val.user && systemtype == 2">{{ val.user }}</td>
                  <td v-else-if="!val.user && orderdetail.username && systemtype == 2">
                    {{ orderdetail.username }}
                  </td>
                  <td v-else>
                    {{ $t("device.orders.nousername") }}
                  </td>
                  <td>{{ val.price }}</td>
                  <td>
                    {{ val.value }}
                    <span v-if="orderdetail.ED_id.substring(0,4)==7710"> {{ $t("device.orders.pack") }}</span>
                    <span v-else>ML</span>
                  </td>
                  <td>{{ val.paytime | formatTimer }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <el-empty :description="$t('device.order.ordererror')"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>